.topleft-div{
position: absolute;
width: 463px;
height: 171px;
left: 0px;
top: 0px;

background-color: #F4F8F9;
border-radius: 0px 0px 90px 0px;
}
.writeyourreview{
position: absolute;
width: 262px;
height: 39px;
left: 43px;
top: 203px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 39px;
/* identical to box height */


color: #000000;
}

.stars-div{
position: absolute;
width: 211px;
height: 33.45px;
left: 43px;
top: 256px;
}

.empty-stars{

width: 35px;
height: 33.45px;

padding-right:9px;
color: #E5E5E5;
}

.filled-stars{

    width: 35px;
    height: 33.45px;
    
    padding-right:9px;
    color: #FFCF52;
    }

.headings{
    position: absolute;
width: auto;
height: 20px;


font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;
}

.or{
    position: absolute;
width: 15px;
height: 20px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;
}

.oneliner-input{
position: absolute;
border:none;
outline:none;
height: 30px;


background: #F4F8F9;
border-radius: 10px;
padding-left: 10px;
font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #888888;
}

.body-input{
    position: absolute;
    border:none;
    outline:none;

    
width: 527px;
height: 181px;
left: 541px;
top: 372px;

    padding-top: 5px;
    
    background: #F4F8F9;
    border-radius: 10px;
    padding-left: 10px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */
    
    
    color: #888888;
    }

.body-input-istyping{
position: absolute;
width: 527px;
height: 181px;
left: 541px;
top: 372px;
outline: none;
border: none;
padding-top: 5px;
background: #FFFFFF;
box-shadow: 0px 4px 30px rgba(22, 32, 43, 0.05);
border-radius: 10px;
padding-left: 10px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
}

.stayanon{
position: absolute;
width: 147px;
height: 30px;
border-style: solid;
display: inline-block;
outline:none;
background-color: transparent;
border-radius: 10px;
border-width: 2px;
border-color: #888888;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */

text-align: center;

color: #888888;

}

.submit{
position: absolute;
width: 110px;
height: 50px;
left: 541px;
top: 584px;
outline:none;
border:none;
background: #E5E5E5;
border-radius: 34px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #FFFFFF;
}

.wordcount{
    position: absolute;
width: auto;
height: 16px;
left: 963px;
top: 559px;
padding-left: 11px;
padding-top: 3px;
font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
/* identical to box height */

text-align: right;

color: #888888;

}
.divider{
position: absolute;
width: 562px;
height: 1px;
left: 529px;
top: 258px;
border: 0 none;


}

.choicebuttons{
display: inline-block;
position: absolute;
width: 410px;
height: 144px;
left: 43px;
top: 360px;
}

.choose3{
position: absolute;
width: 300px;
height: 20px;
left: 43px;
top: 305px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 39px;
/* identical to box height */


color: #000000;
}

.choicebuttonsactual{

width: auto;
height: 30px;
margin-right: 10px;
margin-bottom: 8px;
display: inline-block;
outline:none;
background-color: transparent;
border-radius: 10px;
border-width: 2px;
border-style: solid;
border-color: #888888;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */

text-align: center;

color: #888888;
}

.choicebuttonsactual-clicked{
    border-style: solid;
    width: auto;
    height: 30px;
    margin-right: 10px;
    margin-bottom: 8px;
    display: inline-block;
    outline:none;
    background-color: #F2F0FF;
    border-radius: 10px;
    border-width: 2px;
    border-color: #624BE1;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */
    
    text-align: center;
    
    color: #624BE1;
    }

.handle{
    position: absolute;
    width: 76px;
    height: 26px;
    left: 181px;
    top: 60px;
    
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */
    
    
    color: #624BE1;
    
}

.follower-count{
    position: absolute;
width: 144px;
height: 26px;
left: 160px;
top: 86px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 26px;
/* identical to box height */


color: #000000;
}

.at{
    position: absolute;
width: 16px;
height: 16.42px;
left: 160px;
top: 65px;

color: #624BE1;
}

.profile-image{
    position: absolute;
width: 100px;
height: 100px;
left: 37px;
top: 36px;


filter: drop-shadow(0px 0px 40px rgba(22, 32, 43, 0.1));
border-radius: 30px;
}

.stayanon-Clicked{
    position: absolute;
width: 147px;
height: 30px;
border-style: solid;
outline:none;
    background-color: #F2F0FF;
    border-radius: 10px;
    border-width: 2px;
    border-color: #624BE1;
border-color: #624BE1;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */

text-align: center;

color: #624BE1;
}

.submit-purple{
    position: absolute;
width: 110px;
height: 50px;
left: 541px;
top: 584px;
outline:none;
border:none;
background: #624BE1;
border-radius: 34px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #FFFFFF;
}

.checkmark-button{
    position: absolute;
    top: 597px;
    left: 670px;
    width: 30px;
    height: 30px;
    background: #F2F0FF;
    border-radius: 15px;
    outline: none;
    border: none;
}
.checkmark-yes{
    color:#624BE1;
}
.checkmark-no{
    color:#F2F0FF;
}

.checkmark-message{
    position: absolute;
    top: 602px;
    left: 710px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color:black;
}

.loginInstead{
    position:absolute;
    top: 601px;
    left: 1223px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color:#624BE1;
    cursor: pointer;
}