.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup_innerS {
  position: relative;
  top: 75px;
  left: 326px;
  width: 500px;
height: 538px;
  background: white;

  animation: fadeInAnimation 2s;
  
box-shadow: 0px 4px 30px rgba(22, 32, 43, 0.2);
border-radius: 50px;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Signup{
position: absolute;
width: 163px;
height: 59px;
left: 46px;
top: 33px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 45px;
line-height: 59px;
/* identical to box height */


color: #000000;

}

.haveAnAccount{
position: absolute;
width: 225px;
height: 20px;
left: 46px;
top: 109px;
font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;

}

.haveAnAccount2{
  position: absolute;
  width: 225px;
  height: 20px;
  left: 229px;
  top: 109px;
  cursor: pointer;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */
  
  
  color: #624BE1;
  
  }
.headersS{
position: absolute;

width:300px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;

}

.inputs{
position: absolute;
box-sizing: border-box;
height: 30px;

outline:none;
border:none;
background: #F4F8F9;
border-radius: 10px;
font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */
padding-left: 10px;

color: #888888;

}

.check-box{
position: absolute;
width: 25px;
height: 25px;
left: 35px;
top: 374px;
outline:none;
border:none;
background: #F4F8F9;
border-radius: 7px;
align-items: center;
}

.check-box-checked{
  position: absolute;
width: 25px;
height: 25px;
left: 35px;
top: 374px;
outline:none;
border:none;
background: #624BE1;
border-radius: 7px;
}

.iagree{
  position: absolute;
width: 278px;
height: 20px;
left: 78px;
top: 377px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;
}

.inputerror{
  position: absolute;
width: auto;
height: 20px;
left: 43px;
top: 415px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #FF005C;
}

.googleSignup{
position: absolute;
height: 50px;
left: 35px;
top: 451px;
outline: none;
border:none;
padding: 0px 0px 0px 0px;
/* identical to box height */
border-radius: 34px;
}
.googleIcon{
padding-left:0px;
padding-right:11px;
padding-top:15px;
padding-bottom: 15px;

width: 20px;
height: 20px;
color:#624BE1;

}

.signupbutton{
  position: absolute;
width: 110px;
height: 50px;
left: 353px;
top: 451px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
outline:none;
border:none;
/* identical to box height */


color: #FFFFFF;

background: #624BE1;
border-radius: 34px;
}

.close{
  position: absolute;
  top:20px;
  left:455px;
  width:20px;
  height: 20px;
  color:#624BE1;
}

.signing{
  position: absolute;
  top:240px;
  left: 147px;
  width:200px;
  height: 40px;
  border-radius: 15px;
  background-color: #624BE1;
  outline: none;
  border: none;
  font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
color: white;

}