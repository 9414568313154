.placeforbutton{
  position: relative;
}
.firstButton {
  height: 84px;
  width: 338px;
  position: absolute;
  left: 50%;
  margin-left: -169px;
  top: 50%;
  margin-top: -42px;
  outline: none;
  border: none;
  background: #624be1;
  border-radius: 64px;
  animation: fadeInAnimation 2s;
}
.checkmark {
  width: 50px;
  height: 50px;
  color: #ffffff;
}
.secondButton {
  height: 84px;
  width: 338px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -169px;
  margin-top: -42px;
  outline: none;
  border: none;
  background: #e5e5e5;
  border-radius: 64px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
