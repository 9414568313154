.background-rectangle {
  position: absolute;
  width: 468px;
  height: 100%;
  background: #f4f8f9;
  border-radius: 0px 0px 90px 0px;
}
.inner-content{
  position: absolute;
  top:35px;
  left:35px;
  height: calc(620/700*100vh);
  width: calc(390/1152*100vw);
}
.profile-pic {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 37px;
  top: 36px;

  background: black;
  filter: drop-shadow(0px 0px 40px rgba(22, 32, 43, 0.1));
  border-radius: 30px;
}
.follower-count {
  position: absolute;
  width: 164px;
  height: 26px;
  left: 159px;
  top: 85px;
  

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #000000;
}

.at {
  position: absolute;
  width: 16px;
  height: 16.42px;
  left: 159px;
  top: 64px;

  color: #624be1;
}
.ighandle {
  left: 180px;
  top: 59px;
  position: absolute;
  width: 76px;
  height: 26px;
  

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #624be1;
}

.star {
  position: absolute;
  width: 24px;
  height: 22.94px;
  left: 37px;
  top: 239px;

  color: #ffcf52;
}

.engagement-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 38px;
  top: 172px;
  color: #624be1;
}

.engagement-percentage {
  position: absolute;
  width: 64px;
  height: 39px;
  left: 75px;
  top: 164px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;

  color: #000000;
}
.engagementrate {
  position: absolute;
  width: 118px;
  height: 20px;
  left: 75px;
  top: 200px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #16202b;
}
.basedonreview {
  position: absolute;
  width: 148px;
  height: 20px;
  left: 75px;
  top: 269px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #16202b;
}

.rating {
  position: absolute;
  width: 82px;
  height: 39px;
  left: 75px;
  top: 232px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  /* identical to box height */

  color: #000000;
}

.like {
  position: absolute;
  width: 189px;
  height: 26px;
  left: 37px;
  top: 428px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #000000;
}

.dislike {
  position: absolute;
  width: 257px;
  height: 26px;
  left: 37px;
  top: 511px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #000000;
}
.writebutton {
  position: absolute;
  width: 176px;
  height: 50px;
  left: 37px;
  top: 606px;
  border: none;
  outline: none;
  background: #624be1;
  border-radius: 34px;
  padding-left: 45px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff;
}
.pencil {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 49px;
  top: 616px;
  color: #624be1;
  background: white;
  border-radius: 50%;
  padding: 5px;
}

.button {
  position: absolute;
  left: 54px;
  top: 621px;
  background-color: black;
  border: none;
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  outline: none;
}

.reviews-div {
  
  width: 525px;
  
  max-height: auto;
  padding-bottom: 40px;
  

}

.reviews-name {
  
  height: 26px;
  
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #000000;
}

.reviews-suitcase {
  align-self: center;
  width: 17px;
  height: 14px;
  padding-right: 6px;
  color: #624be1;
}

.reviews-company {
  align-self: center;
  width: 180px;
  height: 20px;
  
 

  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #624be1;
}

.reviews-subject {
 
  width: 255px;
  height: 20px;
  

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}

.star-filled {
  
  width: 18px;
  height: 17.2px;
  padding-right:6.83px;

  color: #ffcf52;
}

.star-empty {
  width: 18px;
  height: 17.2px;
  padding-right:6.83px;

  color: #E5E5E5;
  
  
}

.reviews-subject {
  
  width: 255px;
  height: 20px;
  

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}
.list {
  position: realtive;
  left: 0px;
  top: 0px;
  max-height: fit-content;
}

.reviews-paragraph {
  padding-bottom:10px;
  word-wrap: break-word;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  max-width:525px;
  height: auto;
  color: #656565;
}

.reviews-date {
  width: 152px;
  height: 20px;
  padding-bottom:10px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #656565;
}

.scroll {
  overflow-y: scroll;
  position: relative;
  left: 537px;
  margin-top: 74px;
}

.divforgood {
  position: absolute;
  height: 30px;
  left: 37px;
  top: 462px;
  display: flex;
}

.quality {
  display: inline-block;
  height: 30px;
  background-color: transparent;
  border-width: 2px;
  border-radius: 10px;
  border-color: #624be1;
  border-style:solid;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #624be1;
  margin-right: 10px;
}

.loader-inf{
  position: absolute;
  
  
  
  animation: rotation 2s infinite linear;
  width: 20px;
  height: 20px;
  color: #624BE1;
}

.divforbad {
  position: absolute;
  height: 30px;
  left: 37px;
  top: 545px;
  display: flex;
}

.divfortop {
  
  left: 0px;
  margin-left:0px;
  padding-left:0px;
  height: 30px;
  display: flex;
}

.entirelist{
  position: absolute;
  top:74px;
  left:537px;
}

.quality2 {
  display: inline-block;
  height: 30px;
  outline: none;
  border: none;
  background-color: #F2F0FF;;
  border-radius: 10px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #624be1;
  margin-right: 10px;
}

.bar{
position: absolute;
left: 52px;
width: 266px;
height: 10px;
background-color: white;
border-radius:20px;
}

.bar-purple{
  position: absolute;
  left: 52px;
  
  height: 10px;
  background-color: #624BE1;
  border-radius:20px;
  }
  .bar-lightpurple{
    position: absolute;
    left: 52px;
    
    height: 10px;
    background-color: #CFC9F3;
    border-radius:20px;
    }

.bar-numbering{
position: absolute;
width: 6px;
height: 13px;
left: 38px;

font-family: DM Mono;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 13px;
/* identical to box height */

text-align: center;

color: #16202B;
}

.bar-amount{
  position: absolute;
  width: 6px;
  height: 13px;
  left: 326px;
  
  font-family: DM Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height */
  
  text-align: center;
  
  color: #9D9D9D;
  }

.noreviews{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #624be1;
}
.of-five{
  position: absolute;
width: 82px;
height: 39px;
left: 148px;
top: 241px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 23px;

color: #9D9D9D;
}

.cantWrite{
  position: absolute;
  top: 606px;
  left: 230px;
  width: 170px;
  height: fit-content;
  background: #FF005C;
  border-radius: 20px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 26px;
  border:none;
  outline:none;
  text-align:left;
  
  
  /* identical to box height */


  color: #FFFFFF;
}

.vicky{
  position:absolute;
  color:#624be1;
  width: 25px;
  height: 25px;
  top: 59px;
  left: 276px;
}

.searchAnother{
  position: absolute;
  top:618px;
  left: 235px;
  font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 26px;
/* identical to box height */


color: #624BE1;

}