.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
  position: relative;
  top: 144px;
  left: 326px;
  width: 500px;
  height: 412px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(22, 32, 43, 0.2);
  border-radius: 50px;
  animation: fadeInAnimation 2s;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loader{
  position: absolute;
  animation: rotation 2s infinite linear;
  width: 30px;
  height: 30px;
  color: #624BE1;
  top: 340px;
  left: 1030px;
}

.loginheader{
position: absolute;
width: 126px;
height: 59px;
left: 46px;
top: 33px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 45px;
line-height: 59px;
/* identical to box height */


color: #000000;
}

.donthaveaccount{
    position: absolute;
width: 220px;
height: 20px;
left: 46px;
top: 109px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;
}
.donthaveaccount2{
  position: absolute;
width: 220px;
height: 20px;
left: 211px;
top: 109px;
cursor: pointer;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #624BE1;
}

.error{
  position: absolute;
width: auto;
height: 20px;
left: 39px;
top: 292px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #FF005C;
}
.headers{
position: absolute;
width: 40px;
height: 20px;


font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;

}

.inputs{
position: absolute;
width: 428px;
height: 30px;
left: 35px;

background: #F4F8F9;
border-radius: 10px;
}

.logingoogle{
position: absolute;

height: 50px;
left: 35px;
top: 326px;
outline:none;
border:none;
background: #F2F0FF;
border-radius: 34px;
}

.login{
    position: absolute;
width: 110px;
height: 50px;
left: 353px;
top: 326px;
outline:none;
border:none;
background: #624BE1;
border-radius: 34px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}

.cross{
    position: absolute;
    top: 15px;
    left: 450px;
    width: 20px;
    height: 20px;
    color:#624BE1;
}

.googleIcon{
    width: 20px;
    height: 20px;
    
}