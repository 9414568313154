.trashicon{

    width: 22px;
    height: 25px;

    color: #FF005C;

}

.trashiconNH{

    width: 22px;
    height: 25px;


    color: gray;

}