.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner {
    position: absolute;
    top: 40%;
    left: 40%;
    height: 110px;
    width: 300px;
    background: white;
    border-radius: 15px;
    animation: fadeInAnimation 2s;
    
  }
  .popup_innerF {
    position: absolute;
    top: 40%;
    left: 40%;
    height: 110px;
    width: 300px;
    background: white;
    border-radius: 15px;
    animation: fadeInAnimation 2s;
    
  }
  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .textheader{
      position:absolute;
      top:10px;
      left:10px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    
    
    
    color: #FF005C;
  }

  .closeButton{
      position:absolute;
      top:70px;
      left: 10px;
      border-radius: 15px;
      background-color: #FF005C;
      color:white;
      font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 26px;
    border:none;
    outline:none;
  }