.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);
}
.popup_innerDP {
  position: relative;
  width: 418px;
  height: 233px;
  left: 340px;
  top: 198px;

  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(22, 32, 43, 0.2);
  border-radius: 50px;
  animation: fadeInAnimation 2s;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.areyousure {
  position: absolute;
  width: 273px;
  height: 52px;
  left: 40px;
  top: 34px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  color: #000000;
}

.Permanentmessage {
  position: absolute;
  width: 335px;
  height: 20px;
  left: 40px;
  top: 90px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #9d9d9d;
}

.cancelbuttonDP{
position: absolute;
width: 160px;
height: 50px;
left: 36px;
top: 148px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */

outline: black;
border: black;
border-width: 2px;

text-align: center;
border-radius: 34px;
color: #16202B;

}

.deletebuttonDP{
position: absolute;
width: 160px;
height: 50px;
left: 221px;
top: 148px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */
outline:none;
border:none;
text-align: center;

color: #FFFFFF;

background: #16202B;
border-radius: 34px;
}
