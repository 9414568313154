.contactLine{
    position: absolute;
width: 630px;
height: 20px;
left: 129px;
top: 154px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
/* identical to box height */


color: #000000;

}