.SBpopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);
}
.SBpopup_inner {
  position: relative;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 498px;
  background: white;
  border-radius: 0px 90px 0px 0px;
  animation: fadeInAnimation 1s;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.closeThree {
  position: absolute;
  width: 55px;
  height: 40px;
  padding-left: 30px;
  padding-top: 30px;
}

.home {
  position: absolute;
  width: 45px;
  height: 45px;
  left: 94px;
  top: 26px;
  color: black;
}

.profileimage {
  position: absolute;
  width: 120px;
  height: 120px;
  left: 39px;
  top: 94px;
  background: black;
  filter: drop-shadow(0px 0px 40px rgba(22, 32, 43, 0.1));
  border-radius: 30px;
}

.name-sidebar{
  position: absolute;
width: 201px;
height: 39px;
left: 39px;
top: 228px;

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 39px;
/* identical to box height */


color: #16202B;
}

.myreviews-icon{
width: 25.5px;
height: 30px;
padding-left: 10px;
padding-right: 10px;
}

.aboutus-icon{
width: 23px;
height: 23px;
padding-left: 10px;
padding-right: 10px;
}

.contact-icon{
  width: 23px;
  height: 23px;
  padding-left: 10px;
  padding-right: 10px;
}

.iconheader{
position: absolute;
font-family: DM Sans;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 20px;
color: #16202B;
width: 83px;
height: 20px;
}

.myreviews-tab{
position: absolute;
width: 386px;
height: 52px;
left: 23px;

font-family: DM Sans;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 20px;
color: #624BE1;
text-align: left;
border:none;
outline:none;
background: #F2F0FF;
border-radius: 20px;
vertical-align: middle;
white-space: nowrap;
display: inline-block;
}

.myreviews-tabNC{
  position: absolute;
  width: 386px;
  height: 52px;
  left: 23px;
  
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #16202B;
  text-align: left;
  border:none;
  outline:none;
  background: none;
  border-radius: 20px;
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;
  }

.signoutbutton{
  position: absolute;
  width: 357px;
  height: 50px;
  left: 37px;
  top: 610px;
  background: #624BE1;
  border-radius: 34px;
  font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
/* identical to box height */
outline:none;
border: none;
text-align: center;

color: #FFFFFF;
}

.loginbutton{
    position: absolute;
    width: 357px;
    height: 50px;
    left: 37px;
    top: 225px;
    background: #624BE1;
    border-radius: 34px;
    font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */
  outline:none;
  border: none;
  text-align: center;
  
  color: #FFFFFF;
}