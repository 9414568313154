.Home {
  background-color: #000fff;
}

.icon {
  color: white;
  width: 30px;
  height: 30px;
}

.accountnoexist {
  display: block;
  align-items: center;
  text-align: center;
  position: absolute;
  width: fit-content;
  height: 60px;
  left: 50%;
  
  top: 503px;
  
  transform: translate(-50%, 0);
  border: none;
  background: #ff005c;
  border-radius: 20px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  padding-right: 23px;
}

.icon-forred {
  padding-left: 23px;
  padding-right: 17px;
  color: white;
  width: 30px;
  height: 30px;
}

.icon-cross {
  color: white;
  background-color: black;
  width: 30px;
  height: 30px;
}

.loaderHome {
  position: absolute;
  animation: rotation 2s infinite linear;
  width: 50px;
  height: 50px;
  color: #624be1;
  top: 22px;
  left: 78vw;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.threebars {
  position: absolute;
  width: 55px;
  height: 40px;
  padding-left: 30px;
  padding-top:30px;
}

.divInput {
  position: relative;
  top: calc(280 / 700 * 100vh);
  left: 0;
  width: 100%;
  height: 90px;
}

.input {
  width: 54vw;
  margin-left: 22vw;
  margin-right: 23vw;
  height: 90px;
  background: #f4f8f9;
  border-radius: 70px;
  outline: none;
  border: none;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  box-sizing: border-box;
  padding-left: 39px;
}

.input-istyping {
  width: 54vw;
  margin-left: 22vw;
  margin-right: 23vw;
  height: 90px;
  font-size: 30px;
  box-sizing: border-box;
  padding-left: 39px;
  background: #ffffff;
  box-shadow: 0px 30px 100px 10px rgba(22, 32, 43, 0.05);
  border-radius: 70px;
  outline: none;
  border: none;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
}

.iconinsideInput {
  position: absolute;
  right: 25vw;
  bottom: 15px;
  border: none;
  background-color: black;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  outline: none;
}

.button-istyping {
  position: absolute;
  right: 25vw;
  bottom: 15px;
  background-color: #624be1;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  outline: none;
}
