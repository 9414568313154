.auHeader{
position: absolute;
width: auto;
height: 59px;
left: 127px;
top: 95px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 45px;
line-height: 59px;
color: #000000;
}

.auParagraph{
    position: absolute;
width: 527px;
height: 200px;
left: 127px;
top: 188px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;

color: #000000;

}